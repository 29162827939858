import { fetchPageData, fetchPagePaths, PageData } from "@/core/sanityAPI/fetchers";
import { PageSchema } from "@/schema";
import { GetStaticPropsContext } from "next";
import React from "react";

import { renderBlock } from "../blocks/mapping";

export const SlugPage = (props: PageData<PageSchema>) => {
  return (
    <>
      {props.body?.blocks?.map((block, index) => {
        return renderBlock(block, index);
      })}
    </>
  );
};

export async function getStaticPaths() {
  const pages = await fetchPagePaths(false);

  return {
    paths: pages.map((slug) => ({ params: { slug: slug } })),
    fallback: "blocking",
  };
}

export async function getStaticProps(context: GetStaticPropsContext) {
  const { params, preview, draftMode } = context;

  const slugs = params?.["slug"] || "/";

  const slugPath = Array.isArray(slugs) ? slugs.join("/") : slugs;

  const data = await fetchPageData(slugPath, Boolean(preview || draftMode));

  if (!data) {
    return {
      notFound: true,
    };
  }

  return {
    props: data,
  };
}

export default SlugPage;
